<template>
  <div v-cloak :class="`${!isDesktop ? 'mobile' : '' }`" style="position: relative;">
    <!-- <div v-if="isDesktop" v-show="showVideoAd" class="videoroll">
      <div @click="showVideoAd = !showVideoAd" style="text-align:left;">
        <i class="close" />
      </div>
      <div class="body">
        <div id="yandex_rtb_R-A-2312108-6"></div>
      </div>
    </div> -->
    
    <!-- <MovieAds /> -->

    <div
      v-if="isDesktop && $route.name !== 'games-id' && randWP"
      :style="`background-image: url(/static_files/bg/${randWP}.webp);`"
      class="main-bg"
    />
    <div v-show="$route.name === 'games-id'" class="bg" />
    
    <header v-if="isDesktop">
      <nuxt-link to="/" tag="div" class="logo">
        Esdo Games
      </nuxt-link>
      <SH :type="'db'" />
      <div class="right">
        <a href="https://esdogames.ru/static_files/app/EsdoGamesSetup.exe" download class="load-app">
          <img src="/static_files/icons/download.png?1" alt="">
          Скачать приложение
        </a>
        <Profile />
      </div>
    </header>
    <header v-else>
      <div @click="showMobileMenu = !showMobileMenu" class="icon-burder"></div>
      <SH :type="'db'" />
      <Profile />
    </header>

    <div class="content">
      <aside v-if="isDesktop || showMobileMenu">
        <div v-if="!isDesktop" @click="showMobileMenu = !showMobileMenu" class="icon-close"></div>
        <div class="tops">
          <!-- <div class="title">Платформы</div> -->
          <nuxt-link v-for="item in platforms.slice(0, 3)" :to="`/games?platform=${item.id}`" :key="`top-platform-${item.id}`" tag="div" class="item">
            <span v-if="item.id === 1" class="icon-cup"><svg viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg"><path d="M0 13.772l6.545.902V8.426H0zM0 7.62h6.545V1.296L0 2.198zm7.265 7.15l8.704 1.2V8.425H7.265zm0-13.57v6.42h8.704V0z"></path></svg></span>
            <span v-else-if="item.id === 2" class="icon-cup"><svg viewBox="0 0 21 16" xmlns="http://www.w3.org/2000/svg"><path d="M11.112 16L8 14.654V0s6.764 1.147 7.695 3.987c.931 2.842-.52 4.682-1.03 4.736-1.42.15-1.96-.748-1.96-.748V3.39l-1.544-.648L11.112 16zM12 14.32V16s7.666-2.338 8.794-3.24c1.128-.9-2.641-3.142-4.666-2.704 0 0-2.152.099-4.102.901-.019.008 0 1.51 0 1.51l4.948-1.095 1.743.73L12 14.32zm-5.024-.773s-.942.476-3.041.452c-2.1-.024-3.959-.595-3.935-1.833C.024 10.928 3.476 9.571 6.952 9v1.738l-3.693.952s-.632.786.217.81A11.934 11.934 0 007 12.046l-.024 1.5z"></path></svg></span>
            <span v-else-if="item.id === 3" class="icon-cup"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16"><path d="M3.564 1.357l-.022.02c.046-.048.11-.1.154-.128C4.948.435 6.396 0 8 0c1.502 0 2.908.415 4.11 1.136.086.052.324.215.446.363C11.4.222 7.993 2.962 7.993 2.962c-1.177-.908-2.26-1.526-3.067-1.746-.674-.185-1.14-.03-1.362.141zm10.305 1.208c-.035-.04-.074-.076-.109-.116-.293-.322-.653-.4-.978-.378-.295.092-1.66.584-3.342 2.172 0 0 1.894 1.841 3.053 3.723 1.159 1.883 1.852 3.362 1.426 5.415A7.969 7.969 0 0016 7.999a7.968 7.968 0 00-2.13-5.434zM10.98 8.77a55.416 55.416 0 00-2.287-2.405 52.84 52.84 0 00-.7-.686l-.848.854c-.614.62-1.411 1.43-1.853 1.902-.787.84-3.043 3.479-3.17 4.958 0 0-.502-1.174.6-3.88.72-1.769 2.893-4.425 3.801-5.29 0 0-.83-.913-1.87-1.544l-.007-.002s-.011-.009-.03-.02c-.5-.3-1.047-.53-1.573-.56a1.391 1.391 0 00-.878.431A8 8 0 0013.92 13.381c0-.002-.169-1.056-1.245-2.57-.253-.354-1.178-1.46-1.696-2.04z"></path></svg></span>
            {{ item.name }}
          </nuxt-link>
        </div>
        <div class="tops">
          <nuxt-link to="/" tag="div" class="item">
            <span class="icon-cup home">
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 30 30"><path d="M6.465 11.4c-.956 0-1.733.769-1.733 1.714v10.457c0 .946.777 1.715 1.733 1.715h17.07c.956 0 1.733-.77 1.733-1.715V13.114c0-.945-.777-1.714-1.733-1.714H6.465zm0 15.6C4.554 27 3 25.462 3 23.571V13.114c0-1.89 1.554-3.428 3.465-3.428h17.07c1.911 0 3.465 1.537 3.465 3.428v10.457C27 25.462 25.446 27 23.535 27H6.465zM9.496 4.714a.86.86 0 01-.866-.857A.86.86 0 019.496 3h11.008c.478 0 .866.383.866.857a.861.861 0 01-.866.857H9.496zM7.244 8.058a.861.861 0 01-.866-.858c0-.474.388-.857.866-.857h15.512c.478 0 .866.383.866.857a.861.861 0 01-.866.858H7.244z"></path></svg>
            </span>
            Главная
          </nuxt-link>
          <nuxt-link to="/best-of-the-year" tag="div" class="item">
            <span class="icon-cup">
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 22"><path d="M12.104 14.338c0-.2-.173-.324-.384-.273 0 0-.99.236-1.72.236s-1.72-.236-1.72-.236c-.21-.05-.384.072-.384.273v1.058c0 .2.179.365.397.365h3.414c.218 0 .397-.165.397-.365v-1.058zM16.033.73c0-.403-.355-.73-.794-.73H4.761c-.439 0-.794.327-.794.73v6.02c0 1.884.61 3.599 1.716 4.829A5.738 5.738 0 0010 13.499c1.67 0 3.202-.682 4.317-1.92 1.107-1.23 1.716-2.945 1.716-4.83V.73zM3.211 8.211C2.125 7.84 1.625 6.978 1.625 5.545V2.286h1.468V.827H.831c-.438 0-.794.327-.794.73v3.988c0 2.434 1.268 3.916 3.6 4.262a8.274 8.274 0 01-.426-1.596zm1.549 8.644c-.438 0-.793.327-.793.73v3.612c0 .402.355.73.794.73H15.24c.438 0 .793-.328.793-.73v-3.612c0-.403-.355-.73-.793-.73H4.76zM19.169.827h-2.262v1.46h1.468v3.258c0 1.433-.5 2.295-1.586 2.666a8.269 8.269 0 01-.426 1.596c2.332-.346 3.6-1.828 3.6-4.262V1.557c0-.403-.356-.73-.794-.73z"></path></svg>
            </span>
            Лучшие игры {{ new Date().getFullYear() }}
          </nuxt-link>
        </div>

        <div class="my">
          <span @click="tab = 'filters'" :class="{ active: tab === 'filters' }">Фильтры</span>
          <nuxt-link v-if="!user" to="/signin" tag="span" :class="{ active: tab === 'library' }">Мои игры</nuxt-link>
          <span v-else @click="tab = 'library'" :class="{ active: tab === 'library' }">Мои игры</span>
        </div>

        <div class="filters">
          <template v-if="tab === 'filters'">
            <!-- <div class="title">Фильтры</div> -->
            <div data-select="genre" class="select">
              <strong @click="showSelectMenu('genre')">
                Жанр<template v-if="genre">:</template>
                <span v-if="genre">
                  {{ genres[genres.findIndex(el => el.id === genre)].name[lang] }}
                </span>
                <img class="arrow" src="/static_files/icons/arrow.svg" alt="">
              </strong>
              <ul class="select-menu">
                <li @click="showSelectMenu()">
                  Жанр <sup v-if="genre" @click="genre = ''">Сбросить</sup>
                  <img class="arrow up" src="/static_files/icons/arrow-black.svg" alt="">
                </li>
                <li v-for="g in genres" @click="genre = g.id" :key="`filter-genre-${g.id}`" :title="g.name[lang]">
                  {{ g.name[lang] }}
                  <div v-if="genre === g.id" class="active"></div>
                </li>
              </ul>
            </div>
            <div data-select="platform" class="select">
              <strong @click="showSelectMenu('platform')">
                Платформа<template v-if="platform">:</template>
                <span v-if="platform && platforms[platforms.findIndex(el => el.id === platform)]">
                  {{ platforms[platforms.findIndex(el => el.id === platform)].name }}
                </span>
                <img class="arrow" src="/static_files/icons/arrow.svg" alt="">
              </strong>
              <ul class="select-menu">
                <li @click="showSelectMenu()">
                  Платформа <sup v-if="platform" @click="platform = ''">Сбросить</sup>
                  <img class="arrow up" src="/static_files/icons/arrow-black.svg" alt="">
                </li>
                <li v-for="p in platforms" @click="platform = p.id" :key="`filter-platform-${p.id}`">
                  {{ p.name }}
                  <div v-if="platform === p.id" class="active"></div>
                </li>
              </ul>
            </div>
            <div data-select="gamemode" class="select">
              <strong @click="showSelectMenu('gamemode')">
                Режим <template v-if="gamemode">:</template>
                <span v-if="gamemode">
                  {{ gamemodes[gamemodes.findIndex(el => el.id === gamemode)].name[lang] }}
                </span>
                <img class="arrow" src="/static_files/icons/arrow.svg" alt="">
              </strong>
              <ul class="select-menu">
                <li @click="showSelectMenu()">
                  Режим <sup v-if="gamemode" @click="gamemode = ''">Сбросить</sup>
                  <img class="arrow up" src="/static_files/icons/arrow-black.svg" alt="">
                </li>
                <li v-for="gm in gamemodes" @click="gamemode = gm.id" :key="`filter-mode-${gm.id}`" :title="gm.name[lang]">
                  {{ gm.name[lang] }}
                  <div v-if="gamemode === gm.id" class="active"></div>
                </li>
              </ul>
            </div>
            <div data-select="sort" class="select">
              <strong @click="showSelectMenu('sort')">
                Сортировать<template v-if="sort">:</template>
                <span>
                  {{
                    sort
                      ? sort === 'date'
                        ? 'Дата релиза'
                        : sort === 'popular'
                          ? 'Популярные'
                          : sort === 'rating'
                            ? 'Средний рейтинг'
                            : ''
                      : ''
                  }}
                </span>
                <img class="arrow" src="/static_files/icons/arrow.svg" alt="">
              </strong>
              <ul class="select-menu">
                <li @click="showSelectMenu()">
                  Сортировать <sup v-if="sort" @click="sort = ''">Сбросить</sup>
                  <img class="arrow up" src="/static_files/icons/arrow-black.svg" alt="">
                </li>
                <li v-for="s in sorts" @click="sort = s.name" :key="`filter-sort-${s.name}`">
                  {{ s.title }}
                  <div v-if="sort === s.name" class="active"></div>
                </li>
              </ul>
            </div>

            <div v-if="$route.name === 'games-id'" class="go-to-filter">
              <button @click="goFilter">Показать</button>
            </div>
          </template>
          <template v-else-if="tab === 'library'">
            <!-- <button @click="getFavs" class="refresh">Обновить</button> -->
            <div v-if="user && user.favs.length" class="library">
              <nuxt-link
                v-for="item in user.favs"
                :key="`fav-${item.id}`"
                :to="`/games/${item.id}-${item.slug}`"
                :class="{ active: $route.params.id === `${item.id}-${item.slug}` }"
                class="item"
                tag="div"
              >
                <div class="poster">
                  <i v-if="$route.params.id === `${item.id}-${item.slug}`" @click="delFav(item.id)" class="icon-close" />
                  <img :src="item.backgroundImage" alt="">
                </div>
                <strong>{{ item.name }}</strong>
              </nuxt-link>
            </div>
            <div v-else>
              Ничего не найдено
            </div>
          </template>

          <!-- <div v-show="isDesktop" id="yandex_rtb_R-A-2312108-1"></div> -->
          <div v-if="isDesktop" class='adfinity_block_001'></div>
        </div>
      </aside>
      <div>
        <!-- <div id="yandex_rtb_R-A-2312108-5" class="rsya-block"></div> -->
        <!-- <div class='adfinity_block_002 rsya-block'></div> -->

        <TopGames />
        <nuxt />
      </div>
    </div>
    <div v-show="$route.name === 'games-id'" class="bg-footer" />

    <div v-if="!isDesktop" @click="showMobileMenu = true" class="mobile-filter-btn">
      <nuxt-img
        src="/static_files/icons/filter.svg"
        alt="Avatar"
        preload
        fit="cover"
        width="14"
        height="14"
        format="svg"
      />
      Фильтры
    </div>

    <!-- <div id="yandex_rtb_R-A-2312108-2"></div> -->

    <footer>
      <span>«Эсдо Геймс»</span>
      <a href="mailto:support@esdogames.ru">support@esdogames.ru</a>
    </footer>

    <transition name="toTop">
      <Error v-if="error" :error="error" />
    </transition>

    <div v-if="gloading" class="gloading">
      <div>
        <img src="/static_files/gloader.gif" alt="">
        <p style="text-align:center;">{{ gloading }}</p>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import Vue from 'vue'
import global from '~/mixins/global.js'

Vue.mixin(global)

export default {
  components: {
    SH: () => import('~/components/SH.vue'),
    Error: () => import('~/components/Error.vue'),
    Profile: () => import('~/components/Profile.vue'),
    TopGames: () => import('~/components/TopGames.vue'),
    // MovieAds: () => import('~/components/MovieAds.vue'),
  },
  watch: {
    tab(val) {
      if (val === 'library') {
        this.getFavs()
      }
    },
    platform() {
      this.goFilter()
    },
    genre() {
      this.goFilter()
    },
    sort() {
      this.goFilter()
    },
    gamemode() {
      this.goFilter()
    },
    route() {
//       if (!this.showFS) {
//         const rsyaMobileFS = document.createElement('script')
//         rsyaMobileFS.innerHTML = `
// window.yaContextCb.push(()=>{
// Ya.Context.AdvManager.render({
//   type: 'fullscreen',
//   blockId: 'R-A-2312108-4'
// })
// })`
//         document.body.appendChild(rsyaMobileFS)
//         this.showFS = true
//       }

      if (this.$route.name !== 'games-id') {
        document.querySelector('.bg').style.backgroundImage = ''
        document.querySelector('.bg-footer').style.backgroundImage = ''
      }

      this.showMobileMenu = false
      
      // if (this.$route.name !== 'games') {
      //   this.platform = ''
      //   this.genre = ''
      //   this.sort = ''
      //   this.gamemode = ''
      // }

      this.hideFilters()
      setTimeout(() => {
        this.lazyLoadImgs()
      }, 300)
    }
  },
  computed: {
    ...mapState(['error', 'lang', 'user', 'isDesktop', 'gloading']),
    route() {
      return this.$route.fullPath
    },
  },
  data() {
    const randWP = Math.floor(Math.random() * 5 + 1)
    return {
      randWP,
      showFS: false,
      showVideoAd: true,
      showMobileMenu: false,
      tab: 'filters',
      platform: '',
      platforms: [
        { id: 1, name: 'PC' },
        { id: 2, name: 'PlayStation' },
        { id: 3, name: 'Xbox' },
        { id: 4, name: 'iOS' },
        { id: 8, name: 'Android' },
        { id: 5, name: 'Apple Macintosh' },
        { id: 6, name: 'Linux' },
        { id: 7, name: 'Nintendo' },
        { id: 9, name: 'Atari' },
        { id: 10, name: 'Commodore / Amiga' },
        { id: 11, name: 'SEGA' },
        { id: 12, name: '3DO' },
        { id: 13, name: 'Neo Geo' },
        { id: 14, name: 'Web' }
      ],
      sort: '',
      sorts: [
        { 
          name: 'date',
          title: 'Дата релиза'
        },
        { 
          name: 'popular',
          title: 'Популярные'
        },
        { 
          name: 'rating',
          title: 'Средний рейтинг'
        }
      ],
      gamemode: '',
      gamemodes: [
        {
          id: 1,
          name: {
            en: 'Single player',
            ru: 'Одиночные'
          },
          slug: 'single-player'
        },
        {
          id: 2,
          name: {
            en: 'Multiplayer',
            ru: 'Многопользовательские'
          },
          slug: 'multiplayer'
        },
        {
          id: 3,
          name: {
            en: 'Co-operative',
            ru: 'Ко-оператив'
          },
          slug: 'co-operative'
        },
        {
          id: 4,
          name: {
            en: 'Split screen',
            ru: 'Разделенный экран'
          },
          slug: 'split-screen'
        },
        {
          id: 5,
          name: {
            en: 'Massively Multiplayer Online (MMO)',
            ru: 'Многопользовательские (ММО)'
          },
          slug: 'massively-multiplayer-online-mmo'
        }
      ],
      genre: '',
      genres: [
        {
          id: 4,
          name: {
            en: 'Action',
            ru: 'Экшен',
          },
          slug: 'action'
        },
        {
          id: 3,
          name: {
            en: 'Adventure',
            ru: 'Приключения',
          },
          slug: 'adventure'
        },
        {
          id: 11,
          name: {
            en: 'Arcade',
            ru: 'Аркада',
          },
          slug: 'arcade'
        },
        {
          id: 28,
          name: {
            en: 'Board Games',
            ru: 'Настольные',
          },
          slug: 'board-games'
        },
        {
          id: 17,
          name: {
            en: 'Card',
            ru: 'Карточные',
          },
          slug: 'card'
        },
        {
          id: 40,
          name: {
            en: 'Casual',
            ru: 'Казуальные',
          },
          slug: 'casual'
        },
        {
          id: 34,
          name: {
            en: 'Educational',
            ru: 'Обучение',
          },
          slug: 'educational'
        },
        {
          id: 19,
          name: {
            en: 'Family',
            ru: 'Семейный',
          },
          slug: 'family'
        },
        {
          id: 6,
          name: {
            en: 'Fighting',
            ru: 'Файтинг',
          },
          slug: 'fighting'
        },
        {
          id: 51,
          name: {
            en: 'Indie',
            ru: 'Инди',
          },
          slug: 'indie'
        },
        {
          id: 59,
          name: {
            en: 'Massively Multiplayer',
            ru: 'Мультиплеер',
          },
          slug: 'massively-multiplayer'
        },
        {
          id: 83,
          name: {
            en: 'Platformer',
            ru: 'Платформер',
          },
          slug: 'platformer'
        },
        {
          id: 7,
          name: {
            en: 'Puzzle',
            ru: 'Пазл',
          },
          slug: 'puzzle'
        },
        {
          id: 5,
          name: {
            en: 'RPG',
            ru: 'РПГ',
          },
          slug: 'role-playing-games-rpg'
        },
        {
          id: 1,
          name: {
            en: 'Racing',
            ru: 'Гонки',
          },
          slug: 'racing'
        },
        {
          id: 2,
          name: {
            en: 'Shooter',
            ru: 'Шутер',
          },
          slug: 'shooter'
        },
        {
          id: 14,
          name: {
            en: 'Simulation',
            ru: 'Симулятор',
          },
          slug: 'simulation'
        },
        {
          id: 15,
          name: {
            en: 'Sports',
            ru: 'Спорт',
          },
          slug: 'sports'
        },
        {
          id: 10,
          name: {
            en: 'Strategy',
            ru: 'Стратегия',
          },
          slug: 'strategy'
        }
      ],
    }
  },
  mounted() {
    // this.lazyLoadImgs()
    this.showAds()

    // setInterval(() => {
    //   this.showVideoAd = true
    // }, 5 * 60 * 1000)
    
    // window.addEventListener('scroll', this.hideFilters)
    // window.addEventListener('scroll', this.lazyLoadImgs)

    if (this.isDesktop) window.addEventListener('scroll', this.stickFilters)

    if (this.$route.query.sort) this.sort = this.$route.query.sort
    if (this.$route.query.platform) this.platform = +this.$route.query.platform
    if (this.$route.query.gamemode) this.gamemode = +this.$route.query.gamemode
    if (this.$route.query.genre) this.genre = +this.$route.query.genre

    // this.randWP = Math.floor(Math.random() * 5 + 1)
  },
  methods: {
    showAds() {
      // const adfinityScript = document.createElement('script')
      // adfinityScript.src = 'https://cdn.adfinity.pro/code/esdogames.ru/adfinity.js'
      // adfinityScript.setAttribute('async', 'true')
      // document.head.appendChild(adfinityScript)

      // const clickadilla = document.createElement('script')
      // clickadilla.src = `https://js.wpadmngr.com/static/adManager.js`
      // clickadilla.async = true
      // clickadilla.dataset.admpid = '106739'
      // document.head.appendChild(clickadilla)
      
      const rsya1 = document.createElement('script')
      rsya1.innerHTML = `window.yaContextCb = window.yaContextCb || []`
      document.head.appendChild(rsya1)

      const rsya2 = document.createElement('script')
      rsya2.src = `https://yandex.ru/ads/system/context.js`
      rsya2.async = true
      document.head.appendChild(rsya2)

      setTimeout(() => {
        const rsyaTop = document.createElement('script')
        rsyaTop.innerHTML = `
  window.yaContextCb.push(()=>{
  Ya.Context.AdvManager.render({
  renderTo: 'yandex_rtb_R-A-2312108-5',
  blockId: 'R-A-2312108-5'
  })
  })`
        document.body.appendChild(rsyaTop)

        if (!this.isDesktop) {
          const rsyaFooterMobile = document.createElement('script')
          rsyaFooterMobile.innerHTML = `
  window.yaContextCb.push(()=>{
  Ya.Context.AdvManager.render({
  type: 'floorAd',
  blockId: 'R-A-2312108-3'
  })
  })`
          document.body.appendChild(rsyaFooterMobile)

          const rsyaMobileFS = document.createElement('script')
          rsyaMobileFS.innerHTML = `
window.yaContextCb.push(()=>{
Ya.Context.AdvManager.render({
  type: 'fullscreen',
  blockId: 'R-A-2312108-4'
})
})`
          document.body.appendChild(rsyaMobileFS)

//           const rsyaLenta = document.createElement('script')
//           rsyaLenta.innerHTML = `
// window.yaContextCb.push(()=>{
//   Ya.Context.AdvManager.renderFeed({
//     renderTo: 'yandex_rtb_R-A-2312108-2',
//     blockId: 'R-A-2312108-2'
//   })
// })`
//           document.body.appendChild(rsyaLenta)
        } else {
          const rsyaDesktopVideoAd = document.createElement('script')
          rsyaDesktopVideoAd.innerHTML = `
  window.yaContextCb.push(()=>{
  Ya.Context.AdvManager.render({
  renderTo: 'yandex_rtb_R-A-2312108-6',
  blockId: 'R-A-2312108-6'
  })
  })`
          document.body.appendChild(rsyaDesktopVideoAd)

          const rsyaVertical = document.createElement('script')
          rsyaVertical.innerHTML = `
window.yaContextCb.push(()=>{
  Ya.Context.AdvManager.render({
    renderTo: 'yandex_rtb_R-A-2312108-1',
    blockId: 'R-A-2312108-1'
  })
})`
          document.body.appendChild(rsyaVertical)

          // const script = document.createElement('script')
          // script.src = 'https://vak345.com/s.js?v=14555741a131bf5fcf2531e387341f72'
          // script.setAttribute('async', 'true')
          // document.body.appendChild(script)
        }
      }, 200)
    },
    async delFav(id) {
      const { data } = await this.$axios.delete(`/api/user/favs?id=${id}`)
      if (!data.error) {
        const favs = this.user.favs.filter(el => el.id !== id)
        this.user.favs = favs
        this.$store.commit('setError', { status: 'success', message: 'Удалено из Библиотеки' })
        setTimeout(() => this.$store.commit('clearError'), 1500)
      }
    },
    async getFavs() {
      const { data } = await this.$axios.get(`/api/user/favs`)
      if (!data.error) {
        this.favs = data
      }
    },
    stickFilters() {
      const filtersElem = document.querySelector('.filters')
      if (this.$route.name === 'games-id' && document.documentElement.scrollTop > 410) {
        filtersElem.style.position = 'fixed'
        filtersElem.style.top = '80px'
        filtersElem.style.left = '0px'
        filtersElem.style.width = '20vw'
        filtersElem.style.padding = '10px 30px'
      } else if (document.documentElement.scrollTop > 410) {
        filtersElem.style.position = 'fixed'
        filtersElem.style.top = '0px'
        filtersElem.style.left = '0px'
        filtersElem.style.width = '20vw'
        filtersElem.style.padding = '10px 30px'
      } else {
        filtersElem.style.position = 'relative'
        filtersElem.style.top = ''
        filtersElem.style.left = ''
        filtersElem.style.width = ''
        filtersElem.style.padding = '10px 0px'
      }
    },
    hideFilters() {
      const filtersSelects = document.querySelectorAll('.filters .select ul')
      filtersSelects.forEach(el => {
        el.style.display = 'none'
      })
    },
    showSelectMenu(platform) {
      const filtersSelects = document.querySelectorAll('.filters .select ul')
      filtersSelects.forEach(el => {
        el.style.display = 'none'
      })
      const select = document.querySelector(`[data-select="${platform}"] ul`)
      if (select) {
        select.style.display = 'block'
      }
    },
    goFilter() {
      this.hideFilters()
      let url = `/games?page=0`
      if (this.sort) url += `&sort=${this.sort}`
      if (this.platform) url += `&platform=${this.platform}`
      if (this.gamemode) url += `&gamemode=${this.gamemode}`
      if (this.genre) url += `&genre=${this.genre}`

      this.$router.push(url)
    }
  }
}
</script>

<style>
@media screen and (-webkit-min-device-pixel-ratio: 0) {
  select:focus, textarea:focus, input:focus {
    font-size: 16px;
  }
}
</style>
